@use '@angular/material' as mat;

@mixin card-theme($theme, $typography) {
  $subheading2: map-get($typography, subheading-2);
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  etn-card {
    etn-progress-spinner {
      background-color: map-get($background, card);
    }

    mat-card {
      border-radius: $border-radius;
    }

    &.hide-shadow {
      > mat-card {
        box-shadow: none !important;
      }
    }

    .mat-card-header {
      margin-bottom: 0;

      .mat-card-header-text {
        width: 100%;
      }

      .mat-card-title {
        margin: $unit 0;
        color: mat.get-color-from-palette($primary);
        font-weight: map-get($subheading2, font-weight);
        font-size: map-get($subheading2, font-size);
        line-height: map-get($subheading2, line-height);
      }
    }

    .mat-card-content {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      overflow: hidden;
      border-radius: $border-radius;
    }

    .mat-card-actions {
      margin: 0 !important;
      padding: 0 $padding $padding $padding !important;

      &.no-alt-actions {
        .main-actions {
          width: 100%;
        }
      }
    }

    .mat-card-image {
      width: auto;
      margin: 0 !important;
    }
  }
}
