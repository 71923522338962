@use '@angular/material' as mat;

@mixin empty-state-theme($theme) {
  $accent: map-get($theme, accent);

  etn-empty-state {
    .blui-empty-state-title {
      margin: 0;
    }

    .blui-empty-state-empty-icon-wrapper {
      margin-bottom: 0;
    }

    &.fab-icon {
      .empty-state-icon {
        mat-icon {
          padding: $padding;
          color: mat.get-color-from-palette($accent);
          background-color: mat.get-color-from-palette($accent, 50);
          border-radius: 100%;
        }
      }
    }
  }
}
