@mixin radio-group-theme($theme) {
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);

  etn-radio-group {
    &.flexible-height {
      .mat-list-item-content {
        height: auto !important;
      }
    }
  }
}
