@use '@angular/material' as mat;

@mixin editable-content-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);

  etn-editable-content {
    [contenteditable='true'] {
      border-bottom: 1px solid transparent;
    }

    [contenteditable='true']:active,
    [contenteditable='true']:focus {
      border: none;
      border-bottom: 1px solid mat.get-color-from-palette($primary);
      outline: none;
    }

    etn-icon {
      color: map-get($foreground, secondary-text);
    }
  }
}
