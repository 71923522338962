@use '@angular/material' as mat;

@mixin day-picker-theme($theme) {
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);

  etn-day-picker {
    span {
      color: map-get($foreground, disabled-text);

      &.selected {
        color: inherit;
        border: 1px solid mat.get-color-from-palette($accent);
      }
    }
  }
}
