@import '../../../../../node_modules/angular-calendar/scss/angular-calendar';
@import 'theme/mixins';

@mixin scheduler-theme($theme) {
  etn-scheduler {
    .cal-week-view .cal-time-events .cal-day-column {
      margin-right: $spacing;
    }

    .cal-week-view {
      .cal-hour {
        width: calc(100% + #{$spacing});
      }

      .cal-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $spacing * 2;

        &.cal-today {
          background-color: transparent;
        }

        b {
          text-transform: capitalize;
        }
      }
    }

    mwl-calendar-week-view-current-time-marker {
      display: none;
    }
  }
}
