@use '@angular/material' as mat;

@import 'theme/variables';

@mixin line-animation-theme($theme, $typography) {
  $primary: map-get($theme, primary);

  .motion-demo {
    background: mat.get-color-from-palette($primary, 500);
    border: 5px solid mat.get-color-from-palette($blui-white, 50);
  }

  .online-path {
    stroke: mat.get-color-from-palette($primary, 500);
  }

  .offline-path {
    stroke: mat.get-color-from-palette($blui-gray, 200);
  }
}
