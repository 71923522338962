@use '@angular/material' as mat;

@import '@brightlayer-ui/colors/palette';
@import '@brightlayer-ui/colors-branding/palette';

@mixin status-avatar-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  etn-status-avatar {
    &.primary {
      color: mat.get-color-from-palette($primary, '500-contrast');
      background-color: mat.get-color-from-palette($primary, 500);
    }

    &.accent {
      color: mat.get-color-from-palette($accent, '500-contrast');
      background-color: mat.get-color-from-palette($accent, 500);
    }

    &.warn {
      color: mat.get-color-from-palette($warn, '500-contrast');
      background-color: mat.get-color-from-palette($warn, 500);
    }

    &.red {
      color: map-get($blui-red, contrast, 500);
      background-color: map-get($blui-red, 500);
    }

    &.green {
      color: map-get($blui-green, contrast, 500);
      background-color: map-get($blui-green, 500);
    }

    &.gold {
      color: map-get($blui-white, 500);
      background-color: map-get($blui-gold, 500);
    }

    &.purple {
      color: map-get($blui-purple, contrast, 500);
      background-color: map-get($blui-purple, 500);
    }

    &.gray {
      color: map-get($blui-gray, contrast, 500);
      background-color: map-get($blui-gray, 500);
    }

    &.sky {
      color: map-get($blui-blue, 500);
      background-color: map-get($blui-blue, 50);
    }

    &.blue {
      color: map-get($blui-blue, 500);
      background-color: map-get($blui-blue, 50);
    }

    &.yellow {
      color: map-get($blui-black, 500);
      background-color: map-get($blui-yellow, 500);
    }
  }
}
