@mixin device-settings-theme($theme) {
  app-device-settings {
    .blui-info-list-item-right-content {
      align-items: normal;
    }

    .lobby-url {
      .blui-info-list-item-spacer {
        flex: none !important;
      }

      .blui-info-list-item-right-content {
        margin-left: 0;

        app-lobby-view-input {
          padding-left: 0;
        }
      }
    }
  }
}
