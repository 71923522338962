@mixin notifications-dialog-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);
  $primary: map-get($theme, primary);

  app-notifications-dialog {
    .title {
      color: mat.get-color-from-palette($primary);
      font-size: mat.font-size($typography, title);
      line-height: mat.line-height($typography, title);

      .unread-notification-count {
        font-size: mat.font-size($typography, subheading-1);
        line-height: mat.line-height($typography, subheading-1);
      }
    }

    .mark-all-as-read {
      etn-action {
        .action-label {
          font-weight: mat.font-weight($typography, caption);
          font-size: mat.font-size($typography, caption);
          line-height: mat.line-height($typography, caption);
        }
      }
    }

    app-notification {
      border-top: 1px solid $divider;
    }
  }
}
