@use '@angular/material' as mat;

@mixin info-box-theme($theme) {
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  etn-info-box {
    background-color: map-get($background, card);
    border: 2px solid mat.get-color-from-palette($accent, 100);

    span {
      color: map-get($foreground, secondary-text);
    }
  }
}
