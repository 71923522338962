@use '@angular/material' as mat;

@mixin notifications-panel-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  etn-notifications-panel {
    .overlay {
      background-color: map-get($foreground, base);
    }

    .panel {
      background-color: map-get($background, card);

      @include mat.elevation(4);
    }

    .notification-container {
      &:hover {
        background-color: map-get($background, hover);
      }
    }
  }
}
