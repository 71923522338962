@use '@angular/material' as mat;

@import '../../mixins';

@mixin year-picker-theme($theme) {
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);

  etn-year-picker {
    .action-error {
      color: mat.get-color-from-palette($warn) !important;
    }

    .mat-form-field-suffix {
      display: flex;
      align-items: center;
      margin-top: -1rem;
      margin-bottom: 0 !important;
    }

    &.dense {
      @include dense-form-field($theme);

      .mat-form-field-suffix {
        margin-top: 0;
      }
    }

    mat-form-field {
      &.mat-form-field-appearance-outline {
        .mat-form-field-suffix {
          margin-top: -0.5rem !important;
        }
      }
    }
  }
}
