@use '@angular/material' as mat;

@mixin date-range-picker-theme($theme) {
  $foreground: map-get($theme, foreground);

  etn-date-range-picker {
    .mat-form-field-suffix {
      display: flex;
      align-items: center;
      margin-bottom: 0 !important;
    }

    // ***************************************************************************************
    // fix UI issue with iphone => https://green-motion.atlassian.net/browse/EMOBILITYC-3482 *
    // ***************************************************************************************

    .mat-date-range-input-container {
      > * {
        height: 1.24rem;
      }
    }

    .mat-date-range-input-inner[disabled] {
      color: mat.get-color-from-palette($foreground, secondary-text) !important;
      opacity: 1; // on iphone the opacity is set to 0.4 by default but it's too light
    }

    // ***************************************************************************************
  }
}
