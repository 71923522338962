@use '@angular/material' as mat;

@mixin storage-unit-theme($theme, $typography) {
  $primary: map-get($theme, primary);

  app-storage-unit {
    .x1-input-span,
    .x3-output-span {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: 600;
    }
  }
}
