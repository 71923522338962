@use '@angular/material' as mat;

@mixin charts-page-theme($theme) {
  $primary: map-get($theme, primary);

  app-charts-page {
    .top-bar {
      background-color: mat.get-color-from-palette($primary, 500);
    }

    app-charts-date-picker {
      .mat-form-field-outline {
        background-color: mat.get-color-from-palette($primary, '500-contrast');
        border-radius: $border-radius;
      }
    }
  }
}
