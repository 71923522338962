@mixin page-shell-theme($theme, $typography) {
  $title: map-get($typography, title);
  $primary: map-get($theme, primary);

  app-page-shell {
    .title {
      color: mat.get-color-from-palette($primary);
      font-weight: map-get($title, font-weight);
      font-size: map-get($title, font-size);
    }
  }
}
