@use '@angular/material' as mat;

@mixin input-theme($theme, $typography) {
  $caption: map-get($typography, caption);
  $warn: map-get($theme, warn);

  etn-input {
    .mat-form-field-prefix,
    .mat-form-field-suffix {
      margin-top: 0.3rem;
      margin-bottom: 0 !important;
    }

    .mat-form-field-disabled {
      .mat-form-field-prefix,
      .mat-form-field-suffix {
        margin-top: 0.4rem;
        margin-bottom: 0 !important;
      }
    }

    &.uppercase {
      input {
        text-transform: uppercase;
      }
    }

    .action-toggle-visiblity {
      margin-bottom: $half-unit;

      mat-icon {
        height: 1.5rem !important;
      }
    }
  }

  etn-textarea-input,
  etn-input {
    .maxlength {
      padding-left: $unit;
      font-size: map-get($caption, font-size);
    }
  }

  mat-hint {
    &.green {
      color: map-get($blui-green, 500) !important;
    }

    &.warn {
      color: mat.get-color-from-palette($warn) !important;
    }
  }
}
