@use '@angular/material' as mat;

@mixin asset-details-header-theme($theme, $typography) {
  $primary: map-get($theme, primary);

  app-asset-details-header {
    .icon-online {
      background-color: mat.get-color-from-palette($blui-white, 50);
      border-radius: 50%;
      box-shadow: 0 0 5px mat.get-color-from-palette($primary, 300),
        0 0 20px mat.get-color-from-palette($primary, 50);
    }

    .icon-offline {
      background-color: mat.get-color-from-palette($blui-white, 50);
      border-radius: 50%;
    }

    .asset-details-info-name {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: mat.font-weight($typography, headline);
      font-size: mat.font-size($typography, headline);
    }

    .asset-details-info-status-container {
      font-weight: 600;
      font-size: mat.font-size($typography, body-1);

      .online-status {
        color: mat.get-color-from-palette($primary, 300);
      }

      .offline-status {
        color: mat.get-color-from-palette($blui-gray, 300);
      }

      .charging-extra-status {
        color: mat.get-color-from-palette($blui-lightBlue, 500);
      }

      .discharging-extra-status {
        color: mat.get-color-from-palette($blui-orange, 500);
      }

      .idle-extra-status {
        color: mat.get-color-from-palette($blui-black, 500);
      }
    }

    .back-to-all-assets-button {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: 600;
      font-size: mat.font-size($typography, body-1);
    }
  }
}
