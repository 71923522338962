@mixin login-page-theme($theme, $typography) {
  $caption: map-get($typography, caption);
  $primary: map-get($theme, primary);
  $body2: map-get($typography, body-2);

  app-login-page {
    .certified-container {
      color: mat.get-color-from-palette($primary);
    }
  }
}
