@import 'theme/variables';

@mixin dense-form-field($theme) {
  .mat-form-field-flex {
    padding-top: 0;
  }

  .mat-form-field-underline {
    bottom: 0;
  }

  .mat-form-field-infix {
    padding: 0 0 10px !important;
    border-top: 15px solid transparent !important;
  }

  .mat-form-field-flex:focus-within .mat-form-field-outline-thick {
    color: #007bc1;
  }

  .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }

  .mat-form-field-label {
    padding-top: $half-unit;
  }

  .mat-form-field-should-float {
    .mat-form-field-label-wrapper {
      display: none;
    }
  }

  .mat-form-field-subscript-wrapper {
    top: $spacing + $unit * 1.5 !important;
  }

  mat-hint {
    display: none;
  }

  .mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: 0 0 10px !important;
      border-top: 19px solid transparent !important;
    }

    .mat-form-field-label-wrapper {
      top: -1.85rem;
      padding-top: 1rem;
    }

    .mat-form-field-prefix {
      margin-top: 0;
    }

    .mat-form-field-suffix {
      margin-top: 0.5rem;
    }

    .mat-form-field-outline-gap {
      display: none;
    }
  }
}
