@import 'theme/variables';

@mixin list-page-shell-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);
  $title: map-get($typography, title);
  $background: map-get($theme, background);

  etn-list-page-shell {
    .title-container {
      font-size: map-get($title, font-size);
      background-color: map-get($background, card);
      border-bottom: 1px solid $divider;
      box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%);
      filter: drop-shadow(0 4px 5px rgb(0 0 0 / 14%))
        drop-shadow(0 1px 10px rgb(0 0 0 / 12%));
    }

    etn-table-container {
      margin-top: 0;
    }

    etn-loading {
      background-color: map-get($background, background);
    }
  }
}
