@use '@angular/material' as mat;

@mixin sidenav-theme($theme) {
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  etn-sidenav {
    mat-sidenav {
      max-height: 100vh;
      border: none !important;
    }

    mat-sidenav-content {
      overflow: hidden;
    }

    .blui-drawer-layout-nav-content {
      height: 100%;
    }

    .header-content-title {
      color: mat.get-color-from-palette($primary, '500-contrast');
    }

    .blui-drawer-header-content {
      &.mat-toolbar-single-row {
        &.mat-toolbar {
          height: $top-app-bar-height-compact;
        }
      }
    }

    &.rail {
      .blui-drawer-header-content {
        height: 6.5rem !important;
        background-color: transparent !important;

        .header {
          height: 100%;
          padding: $spacing + $unit 0;
        }

        .header-content-title {
          width: 6.5rem !important;
          padding: 0 !important;
          color: mat.get-color-from-palette($primary, 500);
        }
      }

      mat-sidenav {
        width: 6.5rem !important;
      }

      .blui-drawer-nav-item-rail {
        width: 100%;
      }

      .blui-drawer-nav-item-rail-container {
        border-left: $half-unit solid transparent;
      }

      .blui-drawer-nav-item-active {
        .blui-drawer-nav-item-rail-container {
          background-color: mat.get-color-from-palette($primary, 50);
          border-left: $half-unit solid
            mat.get-color-from-palette($primary, 500);
        }
      }

      .blui-drawer-layout-nav-content {
        margin-left: 6.5rem !important;
      }
    }

    .blui-info-list-item-content {
      height: inherit !important;
    }

    .blui-drawer-footer-content {
      height: 100%;
    }

    blui-drawer-header {
      &.has-content {
        mat-toolbar {
          height: auto !important;
        }
      }
    }

    .blui-drawer-layout {
      &.empty {
        mat-sidenav {
          display: none;
        }

        .blui-drawer-layout-nav-content {
          margin: 0 !important;
        }
      }
    }

    &.transparent-background {
      .mat-drawer-container,
      .mat-drawer-content {
        background-color: transparent !important;
      }
    }
  }
}
