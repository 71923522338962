@use '@angular/material' as mat;

@import 'theme/variables';

@mixin offline-energy-flow-diagram-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $title: map-get($typography, title);
  $caption: map-get($typography, caption);

  .center-diagram-offline {
    background: mat.get-color-from-palette($blui-gray, 50);
    border: $unit solid mat.get-color-from-palette($blui-white, 50);

    .title {
      color: mat.get-color-from-palette($blui-gray, 200);
      font-size: map-get($caption, font-size);
    }

    .value {
      color: mat.get-color-from-palette($blui-white, 50);
      font-size: map-get($title, font-size);
      background: mat.get-color-from-palette($blui-gray, 200);
    }
  }

  .bems-icon-offline {
    app-bems-icon {
      position: relative;
      z-index: 3;
      background-color: mat.get-color-from-palette($blui-gray, 50);
      border-radius: 50%;
      box-shadow: none;
    }
  }

}
