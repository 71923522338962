@mixin map-container($theme) {
  $background: map-get($theme, background);

  etn-map-container {
    etn-card {
      .mat-card {
        border-radius: 100%;
      }
    }

    etn-progress-spinner {
      background-color: map-get($background, background);
    }

    etn-map {
      .center-action {
        etn-icon {
          width: auto;
          padding: $padding;
        }
      }
    }
  }
}
