@use 'sass:math';
@use '@angular/material' as mat;

@import '@brightlayer-ui/colors/palette';

$marker-height: 48px;

@mixin map-theme($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $body-1: map-get($typography, body-1);

  .tooltip {
    z-index: 1;
    width: 0;
    height: 0;

    .triangle {
      fill: map-get($background, card);
    }
  }

  etn-map {
    etn-address-picker {
      .mat-form-field-underline {
        position: initial;
      }
    }

    .placeholder {
      color: map-get($foreground, label);
      background-color: map-get($background, placeholder);
    }

    .mat-form-field-wrapper {
      padding: 0;
    }

    .map-container {
      svg {
        position: absolute;
        top: 0;
        left: 0;
        overflow: visible;

        .inner {
          fill: map-get($background, card);
        }

        .text {
          font-size: 10px;
          fill: black;
        }

        .cluster {
          cursor: pointer;

          .pie {
            &.online {
              fill: map-get($blui-green, 500);
            }

            &.offline {
              fill: map-get($blui-gray, 500);
            }

            &.charging {
              fill: map-get($blui-blue, 500);
            }

            &.error {
              fill: map-get($blui-red, 500);
            }

            &.warning {
              fill: map-get($blui-orange, 500);
            }
          }

          .outer-35 {
            opacity: 0.35;
            fill: map-get($blui-green, 500);
          }

          .outer-20 {
            fill: none;
            stroke: map-get($blui-green, 500);
            stroke-width: 5;
            stroke-opacity: 0.2;
          }
        }

        .user-position {
          .inner {
            fill: map-get($blui-blue, 500);
          }

          .outer {
            fill: map-get($background, card);
            stroke: map-get($blui-blue, 100);
            stroke-width: 1;
          }
        }

        .marker {
          cursor: pointer;
          fill: map-get($blui-black, 500);

          .inner {
            fill: map-get($background, card);
          }

          .pie {
            &.online {
              fill: map-get($blui-green, 500);
              stroke: map-get($blui-green, 900);
            }

            &.offline {
              fill: map-get($blui-gray, 500);
              stroke: map-get($blui-gray, 900);
            }

            &.charging {
              fill: map-get($blui-blue, 500);
              stroke: map-get($blui-blue, 900);
            }

            &.error {
              fill: map-get($blui-red, 500);
              stroke: map-get($blui-red, 900);
            }

            &.warning {
              fill: map-get($blui-orange, 500);
              stroke: map-get($blui-orange, 900);
            }
          }

          .label-front {
            font-size: map-get($body-1, font-size);
            fill: black;
          }

          .label-back {
            font-size: map-get($body-1, font-size);
            stroke: white;
            stroke-width: math.div(map-get($body-1, font-size), 2);
          }
        }

        .address {
          cursor: pointer;
          fill: map-get($blui-orange, 300);

          .inner {
            fill: map-get($background, card);
          }

          .pie {
            stroke: map-get($blui-orange, 300);
          }
        }
      }
    }
  }
}
