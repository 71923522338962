@import 'theme/variables';
@import '@brightlayer-ui/colors/palette';

@mixin app-ev-charger-plug-theme($theme) {
  $background: map-get($theme, background);

  app-ev-charger-plug {
    .content {
      background: map-get($background, background);
    }
  }
}

app-ev-charger-plug {
  i::before {
    display: block;
  }
}
