@use '@angular/material' as mat;

@import 'theme/mixins';

@mixin search-theme($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $input: map-get($typography, body-1);

  etn-search {
    background-color: mat.get-color-from-palette($background, card);

    &.searching {
      border-bottom: mat.get-color-from-palette($background, card);
    }

    input {
      color: map-get($foreground, text);
      font-size: map-get($input, font-size);
    }

    etn-icon {
      color: map-get($foreground, text);
    }
  }

  .etn-search-results-container {
    .results-container {
      background-color: mat.get-color-from-palette($background, card);
      border: 1px solid mat.get-color-from-palette($accent);
      border-top: none;
    }

    .result-container {
      font-size: map-get($input, font-size);

      &:hover {
        background-color: mat.get-color-from-palette($background, hover);
      }
    }

    .no-result-container {
      font-size: map-get($input, font-size);
    }
  }
}
