@import 'theme/variables';

@mixin advanced-peak-shaving-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $header-size: $unit * 2;

  app-advanced-peak-shaving {
    .header-label {
      font-weight: mat.font-weight($typography, subheading-1);
      font-size: $header-size;
    }

    .top-header-label {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: mat.font-weight($typography, subheading-1);
      font-size: $header-size;
    }
  }
}
