@use '@angular/material' as mat;

@import 'theme/variables';

@mixin fab-stepper-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);
  $accent: map-get($theme, accent);

  etn-fab-stepper {
    background-color: mat.get-color-from-palette($primary);

    .mat-horizontal-stepper-header-container {
      display: none;
    }

    .container {
      background: linear-gradient(
        90deg,
        mat.get-color-from-palette($primary) 22.4%,
        rgb(0 123 193 / 20%) 100%
      );
    }

    .mat-horizontal-content-container {
      padding: 0 $padding !important;
    }

    mat-card-actions {
      background-color: map-get($background, card);
    }

    mat-card-title {
      padding-top: $padding;
      color: map-get($foreground, text) !important;
      font-weight: mat.font-weight($typography, title) !important;
      font-size: mat.font-size($typography, title) !important;
    }

    .bullets {
      span {
        background-color: $divider;

        &.current {
          background-color: mat.get-color-from-palette($accent);
        }
      }
    }

    // make sure the dividers take the full width
    // since the stepper has some padding on left/right
    .mat-horizontal-stepper-content {
      blui-info-list-item {
        mat-divider {
          position: absolute;
          left: 0;
          width: 100%;
        }
      }
    }

    @include phone {
      background-color: map-get($background, card);
    }
  }
}
