@use 'sass:math';
@use '@angular/material' as mat;

@mixin pin-code-theme($theme, $typography) {
  $background: map-get($theme, background);
  $accent: map-get($theme, accent);
  $typo: map-get($typography, display-1);
  $font-size: map-get($typo, font-size);

  etn-pin-code {
    input {
      width: $font-size * 1.5;
      height: $font-size * 1.5;
      font-size: $font-size;
      background-color: map-get($background, background);

      &:focus {
        border: 1px solid mat.get-color-from-palette($accent);
      }
    }
  }
}
