@mixin rtl-host {
  :host {
    &.rtl {
      @content;
    }
  }
}

@mixin rtl {
  [dir='rtl'] {
    @content;
  }
}

@mixin dark-theme {
  .dark {
    @content;
  }
}

$phone-max-width: 504px;
$smallscreen-max-width: 1300px;

@mixin phone {
  @media only screen and (max-width: $phone-max-width) {
    @content;
  }
}

@mixin smallscreen {
  @media only screen and (max-width: $smallscreen-max-width) {
    @content;
  }
}
