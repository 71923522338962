@import 'theme/variables';

@mixin eula-page-theme($theme, $typography) {
  $custom-title-font-size: 18px;

  app-eula-page {
    .eula {
      font-weight: mat.font-weight($typography, body-2);
      font-size: mat.font-size($typography, body-2);
      line-height: mat.line-height($typography, body-2);

      h1 {
        margin-bottom: $spacing + $unit;
        font-size: $custom-title-font-size;
        text-align: center;
      }

      .center {
        text-align: center;
      }

      .title {
        display: flex;
        flex-direction: row;
        margin-bottom: $padding;
        font-weight: 600;
        text-transform: uppercase;

        .w1 {
          min-width: $spacing * 2;
        }
      }

      .section {
        .title2 {
          display: flex;
          flex-direction: row;
          margin-bottom: $spacing;
          text-align: justify;
        }

        .w1 {
          min-width: $spacing * 2;
        }
      }
    }
  }
}
