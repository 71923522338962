@import 'theme/variables';

@mixin tab-theme($theme) {
  etn-tab {
    .mat-tab-label {
      width: auto;
    }

    &.full-width {
      .mat-tab-label {
        width: 100%;
      }
    }

    mat-tab-group {
      &.single-tab {
        .mat-tab-label-container {
          display: none;
        }
      }
    }
  }
}
