/* stylelint-disable selector-class-pattern */
@use 'sass:math';

@import 'theme/variables';
@import 'theme/mixins';
@import '../../mixins';

@mixin form-field-theme($theme) {
  etn-form-field,
  etn-address-form-field {
    .content {
      > * {
        width: calc(100% - #{$unit * 2});
        padding-left: $unit * 2;
      }
    }

    @include dense-form-field($theme);

    etn-checkbox-group,
    etn-slide-toggle {
      height: auto !important;
    }

    mat-slide-toggle {
      margin-bottom: 0 !important;

      .mat-slide-toggle-bar {
        margin: 0 !important;
      }
    }

    etn-input {
      .mat-form-field-suffix {
        top: 0 !important;
        margin-bottom: 0 !important;
      }

      input {
        height: 1.25rem;
      }
    }

    etn-color-picker {
      height: auto;

      .color-picker {
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin-top: 0 !important;
      }

      etn-icon {
        margin-top: -6px;
        margin-left: -5px;

        mat-icon {
          font-size: 56px !important;
        }
      }

      mat-form-field {
        margin-left: math.div($form-field-height, 2) + 2;
      }
    }

    etn-phone-input {
      height: auto;
      max-height: none;

      .iti__flag-container {
        top: 1px;
      }
    }

    etn-autocomplete {
      .mat-progress-bar {
        bottom: 0;
        width: calc(100% - #{$unit * 2});
      }

      .mat-form-field-suffix {
        padding-top: $unit;
      }
    }

    etn-datepicker,
    etn-month-picker {
      .mat-form-field-suffix {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      .mat-form-field-appearance-outline {
        .mat-form-field-suffix {
          margin-top: -0.25rem !important;
        }
      }
    }

    etn-textarea-input {
      margin: $unit 0;
    }
  }

  etn-form-field {
    &.multiple {
      .content {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding-left: 0;

        > * {
          width: $form-field-width;
          padding-left: $unit * 2;

          &[colspan='2'] {
            width: ($form-field-width * 2) + ($unit * 2);
          }

          &[colspan='1.5'] {
            width: ($form-field-width * 1.5) + ($unit * 1.5);
          }
        }
      }
    }

    &.flexible-height {
      .mat-list-item-content {
        align-items: normal;
        height: auto;
      }

      .form-field-subtitle {
        span {
          white-space: initial;
        }
      }
    }

    @include phone {
      &.multiple {
        .content {
          > * {
            width: auto;

            &[colspan='2'] {
              width: calc(50% - #{$unit});
            }

            &[colspan='1.5'] {
              width: auto;
            }
          }
        }

        .blui-info-list-item-right-content {
          margin: $unit 0;

          .content {
            width: 100% !important;

            > * {
              width: 100%;
              padding-left: 0;
            }
          }
        }
      }

      .mat-list-item-content {
        flex-direction: column;
        align-items: normal;
        height: auto;

        .mat-list-text {
          overflow: visible;

          .form-field-subtitle {
            white-space: initial;

            > span {
              white-space: initial;
            }
          }
        }
      }

      .blui-info-list-item-icon-wrapper {
        display: none;
      }
    }
  }
}
