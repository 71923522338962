@use '@angular/material' as mat;

@mixin trend-card-theme($theme) {
  $primary: map-get($theme, primary);

  app-trend-card {
    app-bems-icon {
      border: 1px solid mat.get-color-from-palette($primary, 500);
    }

    .icon-border {
      border-color: mat.get-color-from-palette($blui-gray, 200);
    }

    .title {
      color: mat.get-color-from-palette($primary, 500);
    }

    .offline {
      color: mat.get-color-from-palette($blui-gray, 200);
    }
  }
}
