@use '@angular/material' as mat;

@mixin asset-metric-theme($theme, $typography) {
  $primary: map-get($theme, primary);

  app-asset-metric {
    .blui-channel-value-content,
    .metric-property-name {
      color: mat.get-color-from-palette($primary, 500);
    }

    .blui-channel-value-value {
      font-weight: mat.font-weight($typography, title);
      font-size: mat.font-size($typography, title);
    }

    .blui-channel-value-units {
      font-weight: 300;
      font-size: mat.font-size($typography, title);
    }

    .metric-property-name {
      font-weight: 600;
      font-size: mat.font-size($typography, body-1);
    }
  }
}
