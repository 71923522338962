@use '@angular/material' as mat;

@import 'theme/variables';

@mixin time-picker-theme($theme) {
  $background: map-get($theme, background);

  etn-time-picker {
    .mat-form-field-should-float {
      &.mat-form-field-appearance-outline {
        .mat-form-field-infix {
          // when the appearance is outline, the selected value is not aligned with the prefix/suffix icon
          // so we need to adjust the padding of the infix element
          padding: 0.7em 0 1.3em;
        }
      }
    }
  }

  mat-timepicker-dialog {
    .mat-mdc-dialog-actions {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: $spacing;

      // we need to have this otherwise the "OK" button will be to large
      // because material uses a min-width of 64px
      etn-action {
        .mat-raised-button {
          min-width: auto;
        }
      }
    }

    .mat-mdc-dialog-content {
      display: contents;
      padding: 0;
    }

    .outer-dot {
      padding: 0;
      background-color: map-get($background, raised-button);
      border: none;
      cursor: pointer;
    }

    .inner-dot {
      padding: 0;
      background-color: map-get($background, raised-button);
      border: none;
      cursor: pointer;
    }
  }
}
