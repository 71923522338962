@mixin select-theme($theme) {
  etn-select {
    .mat-select-arrow-wrapper {
      transform: inherit;
    }

    .mat-form-field-suffix {
      display: flex;
      margin: 0 !important;
    }

    .no-placeholder {
      .mat-form-field-flex {
        padding-top: 0;
      }
    }
  }
}
