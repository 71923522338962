@use '@angular/material' as mat;

@import 'theme/variables';

@mixin form-page-shell-them($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);
  $title: map-get($typography, title);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);

  etn-form-page-shell {
    etn-card {
      display: block;
      width: $form-width;
      margin: $spacing auto;

      &.search-container {
        margin-bottom: -$spacing;
      }
    }

    etn-table-container {
      etn-card {
        &.table-header {
          margin-bottom: 0;
        }

        &.table-content {
          margin-top: 0;
        }
      }
    }

    &.full-width {
      etn-card {
        width: auto;
      }
    }

    .title-container {
      font-size: map-get($title, font-size);
      background-color: map-get($background, card);
      border-bottom: 1px solid $divider;
      box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%);
      filter: drop-shadow(0 4px 5px rgb(0 0 0 / 14%))
        drop-shadow(0 1px 10px rgb(0 0 0 / 12%));
    }

    etn-loading {
      background-color: map-get($background, background);
    }

    /* stylelint-disable-next-line media-query-no-invalid */
    @media only screen and (max-width: $form-width) {
      etn-card {
        width: auto;
        margin: $padding 0;

        &:first-of-type {
          margin-top: 0;
        }

        mat-card {
          max-width: initial;
          border-top: 1px solid $divider;
          border-bottom: 1px solid $divider;
          border-radius: 0;
          box-shadow: none !important;
        }
      }
    }

    @include phone {
      .title-container {
        color: mat.get-color-from-palette($primary, '500-contrast');
        background-color: mat.get-color-from-palette($primary);

        button {
          background-color: transparent;

          etn-icon {
            color: mat.get-color-from-palette($primary, '500-contrast');
          }
        }
      }
    }
  }
}
