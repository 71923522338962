@use '@angular/material' as mat;

@import '@brightlayer-ui/colors/palette';

@mixin fab-icon-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  etn-fab-icon {
    color: mat.get-color-from-palette($primary);
    background-color: mat.get-color-from-palette($primary, 50);

    &.accent {
      color: mat.get-color-from-palette($accent);
      background-color: mat.get-color-from-palette($accent, 50);
    }

    &.warn {
      color: mat.get-color-from-palette($warn);
      background-color: mat.get-color-from-palette($warn, 50);
    }

    &.primary {
      color: mat.get-color-from-palette($primary);
      background-color: mat.get-color-from-palette($primary, 50);
    }

    &.primary-contrast {
      color: mat.get-color-from-palette($primary, '500-contrast');
      background-color: mat.get-color-from-palette($primary, '50-contrast');
    }

    &.green {
      color: map-get($blui-green, 500);
      background-color: map-get($blui-green, 50);
    }

    &.red {
      color: map-get($blui-red, 500);
      background-color: map-get($blui-red, 50);
    }

    &.blue {
      color: map-get($blui-blue, 500);
      background-color: map-get($blui-blue, 50);
    }

    &.light-blue {
      color: map-get($blui-lightBlue, 500);
      background-color: map-get($blui-lightBlue, 50);
    }

    &.orange {
      color: map-get($blui-orange, 500);
      background-color: map-get($blui-orange, 50);
    }

    &.gray {
      color: map-get($blui-gray, 500);
      background-color: map-get($blui-gray, 50);
    }
  }
}
