@use '@angular/material' as mat;

@import 'ngx-toastr/toastr';
@import '@brightlayer-ui/colors/palette';

@mixin notification-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);

  .toast-container {
    display: flex;
    flex-direction: column;

    .ngx-toastr {
      width: auto !important;
      min-width: 300px;
      max-width: calc(100vw - #{$spacing * 2});
      margin-bottom: $unit * 2;
      padding: $unit * 2;
    }

    .toast-success {
      color: mat.get-color-from-palette($blui-green, '500-contrast');
      background-color: mat.get-color-from-palette($blui-green);
      background-image: none;
    }

    .toast-error {
      color: mat.get-color-from-palette($blui-red, '500-contrast');
      background-color: mat.get-color-from-palette($blui-red);
      background-image: none;
    }

    .toast-info {
      color: mat.get-color-from-palette($blui-blue, '500-contrast');
      background-color: mat.get-color-from-palette($blui-blue);
      background-image: none;
    }

    .toast-warning {
      background-image: none;
    }
  }
}
