@use '@angular/material' as mat;

@import 'theme/variables';

@mixin error-message-component-theme($theme, $typography) {
  app-error-message {
    $primary: map-get($theme, primary);
    $caption: map-get($typography, caption);
    $subheading1: map-get($typography, subheading-1);
    $headline: map-get($typography, headline);

    .title {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: map-get($caption, font-weight);
      line-height: mat.line-height($typography, title);
    }

    .description {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: map-get($subheading1, font-weight);
      font-size: map-get($headline, font-size);
      line-height: mat.line-height($typography, title);
    }

    .support-message {
      color: mat.get-color-from-palette($blui-gray, 500);
      font-weight: map-get($caption, font-weight);
      line-height: mat.line-height($typography, title);
    }
  }
}
