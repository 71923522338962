@mixin button-group-theme($theme) {
  $accent: map-get($theme, accent);

  etn-button-group {
    .mat-button-toggle-appearance-standard {
      .mat-button-toggle-label-content {
        padding: 0 $unit;
        line-height: $spacing + $unit;
      }
    }
  }
}
