@use '@angular/material' as mat;

@import 'theme/variables';

@mixin tabs-page-shell-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);
  $title: map-get($typography, title);
  $background: map-get($theme, background);
  $accent: map-get($theme, accent);

  etn-tabs-page-shell {
    etn-list-page-shell {
      height: 100%;
    }

    etn-form-page-shell {
      height: 100%;
    }
  }
}
