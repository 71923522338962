@mixin charts-date-picker-theme($theme) {
  $day-datepicker-width: 12 * $unit;

  app-charts-date-picker {
    .mat-form-field-infix {
      width: $day-datepicker-width;
    }

    .mat-form-field-prefix {
      margin-bottom: 0 !important;
    }
  }
}
