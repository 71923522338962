@import 'theme/variables';

@mixin file-drop-area-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  etn-file-drop-area {
    border: 2px dotted map-get($foreground, divider);

    &.hovering {
      background-color: map-get($background, hover) !important;
    }
  }
}
