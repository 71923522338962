@mixin progress-spinner-theme($theme) {
  etn-progress-spinner {
    &.small {
      mat-spinner {
        width: 52px !important;
        height: 52px !important;
        margin: $unit;

        svg {
          width: 50px !important;
          height: 50px !important;
        }
      }
    }
  }
}
