/* stylelint-disable selector-class-pattern */
@use '@angular/material' as mat;

@import 'theme/variables';

@mixin dialog-theme($theme) {
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);
  $right-side-content-max-height: calc(100vh - #{$unit * 16});
  $right-side-content-no-action-max-height: calc(100vh - #{$unit * 8});
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

  etn-dialog {
    &.primary {
      .title-container {
        color: mat.get-color-from-palette($primary, '500-contrast');
        background-color: mat.get-color-from-palette($primary, 500);
      }
    }

    &.warn {
      .title-container {
        color: mat.get-color-from-palette($warn, '500-contrast');
        background-color: mat.get-color-from-palette($warn, 500);
      }
    }

    &.accent {
      .title-container {
        color: mat.get-color-from-palette($accent, '500-contrast');
        background-color: mat.get-color-from-palette($accent, 500);
      }
    }

    .actions-container {
      border-top: 1px solid $divider;
    }

    br {
      display: block;
      height: $unit;
      content: '';
    }
  }

  .etn-dialog {
    &.right-side {
      height: 100vh;

      etn-dialog,
      mat-card {
        height: 100%;
        max-height: 100%;
        border-radius: 0;
      }

      .dialog-content {
        height: 100%;
        max-height: $right-side-content-max-height;
      }

      &.no-action {
        .dialog-content {
          max-height: $right-side-content-no-action-max-height;
        }

        &.no-title {
          .dialog-content {
            max-height: 100%;
          }
        }
      }
    }

    &.animate__zoomIn,
    &.animate__zoomOut {
      --animate-duration: 0.3s;
    }

    &.animate__slideInRight,
    &.animate__slideOutRight {
      --animate-duration: 0.5s;
    }
  }
}
