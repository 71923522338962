@use '@angular/material' as mat;

@mixin image-gallery-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  etn-image-gallery {
    etn-icon {
      color: map-get($foreground, base);
      background-color: map-get($background, card);
    }

    etn-image {
      &:hover {
        @include mat.elevation(4);
      }
    }
  }
}
