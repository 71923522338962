@use '@angular/material' as mat;

@import '../../../../libs/layout/src/theming';
@import '../../../../libs/ui/src/theming';
@import '../app/bems/features/dashboard/components/energy-point-side-info/energy-point-side-info.theme';
@import '../app/bems/features/dashboard/components/energy-point/energy-point.theme';
@import '../app/bems/layout/bems-icon/bems-icon.theme';
@import '../app/bems/features/dashboard/components/offline-energy-flow-diagram/offline-energy-flow-diagram.theme';
@import '../app/bems/features/dashboard/components/total-power-usage/total-power-usage.theme';
@import '../app/bems/features/dashboard/components/trend-card/trend-card.theme';
@import '../app/bems/features/errors/components/error-message/error-message.theme';
@import '../app/bems/features/notifications/components/notification/notification.theme';
@import '../app/bems/features/notifications/components/notifications-dialog/notifications-dialog.theme';
@import '../app/bems/layout/app-shell/app-shell.theme';
@import '../app/bems/layout/line-animation/line-animation.theme';
@import '../app/bems/layout/top-bar/top-bar.theme';
@import '../app/login-portal/features/account/components/password-validation-line/password-validation-line.theme';
@import '../app/login-portal/features/login/pages/login-page/login-page.theme';
@import '../app/login-portal/features/oauth/components/authorize-application/authorize-application.theme';
@import '../app/login-portal/features/terms/components/eula-info/eula-info.theme';
@import '../app/login-portal/layout/app-shell/app-shell.theme';
@import '../app/login-portal/layout/body-2/body-2.directive.theme';
@import '../app/login-portal/layout/page-shell/page-shell.theme';
@import '../app/bems/layout/card-title/card-title.theme';
@import '../app/bems/features/assets/components/asset-details-header/asset-details-header.theme';
@import '../app/bems/features/assets/components/asset-metric/asset-metric.theme';
@import '../app/bems/features/assets/components/ev-charger-plug/ev-charger-plug.theme';
@import '../app/bems/features/assets/components/storage-unit/storage-unit.theme';
@import '../app/bems/layout/body-1/body-1.directive.theme';
@import '../app/bems/layout/title/title.directive.theme';
@import '../app/bems/layout/headline/headline.directive.theme';
@import '../app/bems/features/users/components/permission-header/permission-header.theme';
@import '../app/bems/features/users/components/invitation-header/invitation-header.theme';
@import '../app/bems/features/lobby-view/pages/lobby-view-page/lobby-view-page.theme';
@import '../app/bems/features/charts/components/charts-date-picker/charts-date-picker.theme';
@import '../app/bems/features/settings/components/device-settings/device-settings.theme';
@import '../app/bems/features/charts/pages/charts-page/charts-page.theme';
@import '../app/bems/features/settings/components/advanced-peak-shaving/advanced-peak-shaving.theme';

@mixin app-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $background: map-get($theme, background);

  @include bems-top-bar-theme($theme, $typography);
  @include bems-app-shell-theme($theme, $typography);
  @include login-app-shell-theme($theme, $typography);
  @include login-page-theme($theme, $typography);
  @include error-message-component-theme($theme, $typography);
  @include password-validation-line-component-theme($theme, $typography);
  @include trend-card-theme($theme);
  @include total-power-usage-theme($theme, $typography);
  @include offline-energy-flow-diagram-theme($theme, $typography);
  @include line-animation-theme($theme, $typography);
  @include energy-point-theme($theme, $typography);
  @include bems-icon-theme($theme, $typography);
  @include energy-point-side-info-theme($theme, $typography);
  @include eula-page-theme($theme, $typography);
  @include notifications-dialog-theme($theme, $typography);
  @include bems-notification-theme($theme, $typography);
  @include page-shell-theme($theme, $typography);
  @include authorize-application-theme($theme, $typography);
  @include body-1-theme($theme, $typography);
  @include body-2-theme($theme, $typography);
  @include card-title-theme($theme, $typography);
  @include asset-details-header-theme($theme, $typography);
  @include asset-metric-theme($theme, $typography);
  @include app-ev-charger-plug-theme($theme);
  @include title-theme($theme, $typography);
  @include headline-theme($theme, $typography);
  @include storage-unit-theme($theme, $typography);
  @include permission-header-theme($theme, $typography);
  @include invitation-header-theme($theme, $typography);
  @include lobby-view-page-theme($theme, $typography);
  @include charts-date-picker-theme($theme);
  @include device-settings-theme($theme);
  @include charts-page-theme($theme);
  @include advanced-peak-shaving-theme($theme, $typography);

  a {
    color: mat.get-color-from-palette($primary);
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: map-get($background, hover);
    border-radius: $half-unit;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    height: 134px;
    background: mat.get-color-from-palette($primary, 50);
    border-radius: $half-unit;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: mat.get-color-from-palette($primary, 100);
  }

  .mat-form-field-subscript-wrapper {
    overflow: visible;
    white-space: nowrap;
  }

  etn-list-page-shell {
    overflow: visible;

    .content-container {
      margin-top: 0;

      > etn-card {
        > mat-card {
          margin-bottom: $half-unit;
        }
      }
    }

    etn-table-container {
      .alt-table-header,
      .table-header {
        > mat-card {
          overflow: hidden;
          border-radius: $border-radius $border-radius 0 0;
        }
      }

      .table-content {
        > mat-card {
          overflow: hidden;
          border-radius: 0 0 $border-radius $border-radius;
        }
      }
    }
  }
}
