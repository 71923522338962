@use '@angular/material' as mat;

@mixin bems-notification-theme($theme, $typography) {
  app-notification {
    .date {
      font-weight: mat.font-weight($typography, caption);
      font-size: mat.font-size($typography, caption);
      line-height: mat.line-height($typography, caption);
    }
  }
}
