@use '@angular/material' as mat;

@import 'theme/variables';

@mixin login-app-shell-theme($theme, $typography) {
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $layout-padding: 64px;

  app-login-portal-shell {
    etn-loading {
      .overlay {
        background-color: map-get($background, card);
      }
    }

    etn-card {
      mat-card {
        padding: $layout-padding 0;
        overflow: hidden;
        background-color: transparent !important;
        box-shadow: none !important;

        .card-content {
          background-color: map-get($background, card);
          border-radius: $border-radius;

          etn-loading {
            top: $layout-padding;
            left: 0;
            width: calc(100% - #{$border-radius * 4});
            height: calc(100% - #{$layout-padding * 2 + ($border-radius * 2)});
            padding: $border-radius;
          }
        }
      }
    }

    .navigation-container {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: mat.font-weight($typography, title);
      font-size: mat.font-size($typography, title);

      button {
        color: mat.get-color-from-palette($primary, 500);
      }
    }

    .logo-container {
      color: mat.get-color-from-palette($primary, 500);
      font-size: mat.font-size($typography, body-2);

      .brightlayer {
        font-weight: mat.font-weight($typography, title);
      }
    }

    .app-title {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: mat.font-weight($typography, title);
      line-height: mat.line-height($typography, title);
    }
  }
}
