@use '@angular/material' as mat;

@mixin lobby-view-page-theme($theme, $typography) {
  $title: map-get($typography, display-2);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $custom-top-bar-title-font-size: 40px;

  app-lobby-view-page {
    .title-container {
      .title {
        color: mat.get-color-from-palette($primary, 500);
        font-weight: map-get($title, font-weight);
        font-size: $custom-top-bar-title-font-size;
        line-height: map-get($title, line-height);
      }
    }

    .location {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }

    app-weather,
    .blui-channel-value-value {
      color: mat.get-color-from-palette($foreground, secondary-text);
    }
  }
}
