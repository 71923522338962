@use '@angular/material' as mat;

@mixin fab-card-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  etn-fab-card {
    &.background {
      background-color: mat.get-color-from-palette($primary);

      .container {
        background: linear-gradient(
          90deg,
          mat.get-color-from-palette($primary) 22.4%,
          rgb(0 123 193 / 20%) 100%
        );
      }
    }

    mat-card {
      max-height: calc(100vh - 48px);
    }

    mat-card-title {
      margin: 0 !important;
      padding-top: $padding;
      color: map-get($foreground, text) !important;
      font-weight: mat.font-weight($typography, title) !important;
      font-size: mat.font-size($typography, title) !important;
    }

    .mat-card-content {
      height: 100%;
      overflow: auto;
    }

    @include phone {
      background-color: map-get($background, card);

      mat-card {
        max-width: initial;
        max-height: 100%;
        border-radius: initial;
        box-shadow: none !important;
      }

      .container {
        background: none;
      }
    }
  }
}
