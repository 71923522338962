@use '@angular/material' as mat;

@mixin cookie-consent-theme($theme, $typography) {
  $title: map-get($typography, title);
  $foreground: map-get($theme, foreground);

  etn-cookie-consent {
    background-color: transparentize(map-get($foreground, base), 0.5);

    .title {
      font-size: map-get($title, font-size);
    }
  }
}
