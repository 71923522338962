@use '@angular/material' as mat;

@mixin hierarchy-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $divider: map-get($foreground, divider);
  $accent: map-get($theme, accent);

  etn-hierarchy {
    .node {
      background-color: map-get($background, card);

      @include mat.elevation(4);

      etn-icon {
        &.node-icon {
          color: mat.get-color-from-palette($accent);
        }
      }

      .collapse {
        background-color: map-get($background, background);

        &.collapsed {
          color: mat.get-color-from-palette($accent);
        }
      }
    }

    .left-line,
    .right-line {
      border-top: 1px solid $divider;
    }

    .vertical-line {
      border-left: 1px solid $divider;
    }
  }
}
