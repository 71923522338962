@use '@angular/material' as mat;

@mixin rating-theme($theme) {
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);

  etn-rating {
    color: map-get($foreground, divider);

    etn-icon {
      &.full {
        color: mat.get-color-from-palette($accent);
      }
    }
  }
}
