@use '@angular/material' as mat;

@import 'theme/variables';

@mixin action-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $divider: map-get($foreground, divider);
  $light: mat.$light-theme-foreground-palette;

  etn-action {
    &.action-error {
      etn-icon {
        color: mat.get-color-from-palette($warn);
      }
    }

    .mat-button-wrapper {
      display: flex;
    }

    &.icon-position-right {
      .mat-button-wrapper {
        flex-direction: row-reverse;
      }
    }

    button {
      &.mat-button {
        line-height: 2.25rem;
      }

      &.mat-light {
        color: map-get($foreground, hint-text);
      }

      &.mat-primary-transparent {
        color: mat.get-color-from-palette($primary);
        background-color: transparent;
      }

      &.mat-light-transparent {
        color: map-get($foreground, hint-text);
        background-color: transparent;
      }

      &.mat-accent-transparent {
        color: mat.get-color-from-palette($accent);
        background-color: transparent;
      }

      &.mat-warn-transparent {
        color: mat.get-color-from-palette($warn);
        background-color: transparent;
      }

      &.mat-transparent {
        background-color: transparent;
      }

      &.icon-only {
        min-width: 36px;

        .mat-button-wrapper {
          justify-content: center;
          min-height: 36px;
        }
      }

      &.border {
        border: 1px solid map-get($foreground, divider);

        &.mat-accent,
        &.mat-accent-transparent {
          border: 1px solid mat.get-color-from-palette($accent) !important;
        }

        &.mat-primary,
        &.mat-primary-transparent {
          border: 1px solid mat.get-color-from-palette($primary) !important;
        }

        &.mat-warn {
          border: 1px solid mat.get-color-from-palette($warn) !important;
        }
      }
    }

    &.loading {
      /* stylelint-disable-next-line no-descending-specificity */
      .mat-button-wrapper {
        .action-label-container,
        etn-icon {
          opacity: 0;
        }
      }

      button {
        &.mat-accent:not(.mat-button-base) {
          mat-progress-spinner {
            circle {
              stroke: mat.get-color-from-palette($accent, '500-contrast');
            }
          }
        }

        &.mat-warn:not(.mat-button-base) {
          mat-progress-spinner {
            circle {
              stroke: mat.get-color-from-palette($warn, '500-contrast');
            }
          }
        }

        &.mat-primary:not(.mat-button-base) {
          mat-progress-spinner {
            circle {
              stroke: mat.get-color-from-palette($primary, '500-contrast');
            }
          }
        }

        &.mat-light:not(.mat-button-base) {
          mat-progress-spinner {
            circle {
              stroke: mat.get-color-from-palette($light, 'disabled-text');
            }
          }
        }
      }
    }

    .counter {
      background-color: mat.get-color-from-palette($warn);
    }

    &.disabled {
      button {
        &.border {
          border: 1px solid map-get($foreground, divider) !important;

          &.mat-accent {
            border: 1px solid mat.get-color-from-palette($accent, 100) !important;
          }

          &.mat-primary {
            border: 1px solid mat.get-color-from-palette($primary, 100) !important;
          }
        }
      }
    }
  }

  etn-action-container {
    etn-action {
      margin-right: $spacing;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}
