@use '@angular/material' as mat;

@mixin energy-point-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $caption: map-get($typography, caption);
  $body2: map-get($typography, body-2);
  $subheading2: map-get($typography, subheading-2);
  $subheading1: map-get($typography, subheading-1);

  app-energy-point {
    .main-border {
      border: 1px solid mat.get-color-from-palette($primary, 300);
    }

    .point-wrapper {
      .energy-point-number {
        color: mat.get-color-from-palette($blui-white, 50);
        font-size: map-get($caption, font-size);
        background-color: mat.get-color-from-palette($primary, 500);
      }

      .bems-point-offline {
        position: relative;
        z-index: 3;
        background-color: mat.get-color-from-palette($blui-gray, 50);
        border-radius: 50%;
        box-shadow: none;
      }

      app-bems-icon {
        background-color: mat.get-color-from-palette($blui-white, 50);
        box-shadow: 0 0 5px mat.get-color-from-palette($primary, 300),
          0 0 20px mat.get-color-from-palette($primary, 50);
      }

      .bems-icon-offline {
        background-color: mat.get-color-from-palette($blui-gray, 50);
        box-shadow: none;
      }

      .small-ep {
        color: mat.get-color-from-palette($blui-purple, 400) !important;
        font-size: map-get($caption, font-size) !important;
      }

      .subtitle {
        color: mat.get-color-from-palette($primary, 500);
        font-weight: map-get($caption, font-weight);
        font-size: map-get($body2, font-size);
      }

      .title {
        color: mat.get-color-from-palette($primary, 500);
        font-weight: map-get($caption, font-weight);
        font-size: map-get($subheading2, font-size);
      }

      .text-offline {
        color: mat.get-color-from-palette($blui-gray, 200);
      }

      .learn-more {
        color: mat.get-color-from-palette($primary, 500);
        font-weight: map-get($subheading1, font-weight);
        font-size: map-get($body2, font-size);
        cursor: pointer;
      }

      .none-connected-text {
        color: mat.get-color-from-palette($blui-gray, 200);
        font-size: map-get($body2, font-size);
      }

      .value {
        font-weight: map-get($subheading1, font-weight);
      }
    }
  }
}
