@use '@angular/material' as mat;

@mixin user-menu-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);
  $title: map-get($typography, title);

  etn-user-menu {
    .avatar {
      color: mat.get-color-from-palette($primary);
      font-size: map-get($title, font-size);
      line-height: map-get($title, line-height);
      background-color: mat.get-color-from-palette($primary, 50);
    }

    .user-name {
      color: mat.get-color-from-palette($primary);
    }

    &:hover:not(.avatar),
    &.opened:not(.avatar) {
      background-color: mat.get-color-from-palette($primary);

      .user-name {
        color: mat.get-color-from-palette($primary, '500-contrast');
      }
    }
  }

  .etn-user-menu-items {
    .menu-items-header {
      font-weight: map-get($title, font-weight);
      font-size: map-get($title, font-size);
      line-height: map-get($title, line-height);
      border-color: $divider;
    }

    .avatar {
      color: mat.get-color-from-palette($primary);
      background-color: mat.get-color-from-palette($primary, 50);
    }
  }
}
