@use '@angular/material' as mat;

@mixin energy-point-side-info-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $subheading2: map-get($typography, subheading-1);
  $caption: map-get($typography, caption);
  $body2: map-get($typography, body-2);

  app-energy-point-side-info {
    .main-border {
      border: 1px solid mat.get-color-from-palette($primary, 300);
    }

    .point-wrapper {
      .e-p-number {
        color: mat.get-color-from-palette($blui-white, 50);
        background-color: mat.get-color-from-palette($primary, 500);
      }

      .line {
        background-color: mat.get-color-from-palette($blui-purple, 400);
      }

      app-bems-icon {
        background-color: mat.get-color-from-palette($blui-white, 50);
        box-shadow: 0 0 5px mat.get-color-from-palette($blui-purple, 50),
          0 0 20px mat.get-color-from-palette($blui-purple, 50);
      }

      .bems-icon-offline {
        background-color: mat.get-color-from-palette($blui-gray, 50);
        box-shadow: none;
      }

      .subtitle {
        color: mat.get-color-from-palette($primary, 500);
      }

      .title {
        color: mat.get-color-from-palette($blui-purple, 400);
        font-weight: map-get($caption, font-weight);
        font-size: map-get($body2, font-size);
      }

      .value {
        font-weight: map-get($subheading2, font-weight);
        font-size: map-get($subheading2, font-size);
      }
    }
  }
}
