@use '@angular/material' as mat;

@import 'theme/variables';

@mixin total-power-usage-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $title: map-get($typography, title);
  $caption: map-get($typography, caption);

  app-total-power-usage {
    .center-diagram-online {
      background: radial-gradient(
        50% 50% at 50% 50%,
        mat.get-color-from-palette($primary, 300) 50%,
        mat.get-color-from-palette($blui-gray, 500) 100%
      );
      background-color: mat.get-color-from-palette($primary, 500);
      border: $unit solid mat.get-color-from-palette($blui-white, 50);
      box-shadow: 0 0 20px 0 mat.get-color-from-palette($primary, 50);

      .title {
        color: mat.get-color-from-palette($primary, '500-contrast');
        font-weight: mat.font-weight($typography, title);
        font-size: map-get($caption, font-size);
        line-height: mat.line-height($typography, title);
      }

      .value {
        color: mat.get-color-from-palette($primary, '400-contrast');
        font-size: map-get($title, font-size);
        background: mat.get-color-from-palette($primary, 400);
      }
    }

    .center-diagram-offline {
      background: mat.get-color-from-palette($blui-gray, 50);
      border: $unit solid mat.get-color-from-palette($blui-white, 50);

      .title {
        color: mat.get-color-from-palette($blui-gray, 200);
        font-size: map-get($caption, font-size);
      }

      .value {
        color: mat.get-color-from-palette($blui-white, 50);
        font-size: map-get($title, font-size);
        background: mat.get-color-from-palette($blui-gray, 200);
      }
    }
  }
}
