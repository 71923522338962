@import 'theme/variables';

@mixin checkbox-group-theme($theme) {
  $foreground: map-get($theme, foreground);
  $icon-color: map-get($foreground, secondary-text);

  etn-checkbox-group {
    .mat-checkbox-label {
      display: flex;
      gap: $unit;
      align-items: center;
    }

    etn-icon {
      color: $icon-color;
    }
  }
}
