@use '@angular/material' as mat;

@import '@brightlayer-ui/colors/palette';

@mixin icon-theme($theme, $typography) {
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);

  etn-icon {
    svg {
      display: block;
    }

    .counter {
      background-color: mat.get-color-from-palette($warn) !important;
    }

    &.primary {
      color: mat.get-color-from-palette($primary) !important;
    }

    &.primary-contrast {
      color: mat.get-color-from-palette($primary, '500-contrast') !important;
    }

    &.accent {
      color: mat.get-color-from-palette($accent) !important;
    }

    &.grey {
      color: map-get($foreground, secondary-text) !important;
    }

    &.disabled {
      color: map-get($foreground, disabled-text) !important;
    }

    &.warn {
      color: mat.get-color-from-palette($warn) !important;
    }

    &.green {
      color: map-get($blui-green, 500) !important;
    }

    &.red {
      color: map-get($blui-red, 500) !important;
    }

    &.blue {
      color: map-get($blui-blue, 500) !important;
    }

    &.light-blue {
      color: map-get($blui-lightBlue, 500) !important;
    }

    &.orange {
      color: map-get($blui-orange, 500) !important;
    }

    mat-icon {
      color: inherit !important;
    }
  }

  .mat-mini-fab {
    etn-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      color: mat.get-color-from-palette($accent, '500-contrast');
      transform: translate(-50%, -50%);
    }
  }
}
