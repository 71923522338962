@use '@angular/material' as mat;

@import 'theme/variables';

$top-bar-height: 56px;

@mixin bems-app-shell-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $subheading2: map-get($typography, subheading-2);
  $subheading1: map-get($typography, subheading-1);
  $caption: map-get($typography, caption);

  app-shell {
    .app-shell-container {
      &.fullscreen {
        mat-sidenav {
          display: none;
        }

        .blui-drawer-layout-nav-content {
          margin-left: 0 !important;
        }
      }
    }

    .sidenav-content {
      height: 100%;

      app-not-found-page,
      app-internal-server-error-page,
      app-invitation-expired-page,
      app-no-device-page,
      app-service-unavailable-page,
      app-forbidden-page,
      app-session-expired-page {
        display: block;
        height: 100vh;
      }

      &.has-menu {
        app-top-bar {
          .title {
            color: mat.get-color-from-palette($primary, '600-contrast');
          }
        }
      }
    }

    etn-loading {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100000;
      width: 100vw;
      height: 100vh;
    }

    etn-list-page-shell {
      padding: $spacing;

      etn-card {
        display: block;
      }
    }

    etn-table-container {
      margin: 0;
    }

    .footer {
      font-weight: map-get($subheading1, font-weight);
      font-size: map-get($caption, font-size);
    }
  }
}
