@use '@angular/material' as mat;

@mixin nav-tab-theme($theme) {
  $primary: map-get($theme, primary);

  etn-nav-tab {
    .mat-tab-nav-bar {
      border-bottom: none;
    }

    &.light {
      .mat-tab-link-container {
        .mat-tab-link {
          min-width: auto;

          &.mat-tab-label-active {
            color: mat.get-color-from-palette($primary, 500);
            opacity: 1;
          }
        }
      }
    }

    &.primary {
      .mat-tab-nav-bar {
        @include mat.elevation(4);

        &.mat-primary {
          .mat-tab-link-container {
            background-color: mat.get-color-from-palette($primary, 700);

            .mat-tab-link {
              color: mat.get-color-from-palette($primary, 200);
              text-transform: uppercase;
              opacity: 1;

              &.mat-tab-label-active {
                color: mat.get-color-from-palette($primary, '500-contrast');
              }
            }

            .mat-ink-bar {
              background-color: mat.get-color-from-palette(
                $primary,
                '700-contrast'
              );
            }
          }
        }
      }
    }
  }
}
