@mixin score-card-theme($theme) {
  etn-score-card {
    .blui-score-card-action-items-wrapper {
      > * {
        width: auto;
        height: auto;
      }
    }
  }
}
