@use 'sass:math';
@use '@angular/material' as mat;

@import 'theme/variables';

@mixin table-theme($theme, $typography) {
  $body2: map-get($typography, body-2);
  $subheading1: map-get($typography, subheading-1);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  etn-table {
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    &.hide-column-header {
      thead {
        display: none;
      }
    }

    etn-table-actions {
      button {
        background-color: transparent !important;
      }
    }

    etn-empty-state {
      .blui-empty-state-title {
        font-size: map-get($subheading1, font-size);
      }
    }

    etn-table-expand-toggle {
      button {
        background-color: transparent !important;
      }
    }

    .mat-header-cell {
      color: map-get($foreground, text);
      font-weight: map-get($subheading1, font-weight);
      font-size: map-get($subheading1, font-size);
    }

    .mat-cell {
      font-weight: map-get($body2, font-weight);
      font-size: map-get($body2, font-size);
    }

    .mat-column-actions {
      width: $spacing * 2;
      padding-right: $unit !important;
      text-align: right;
    }

    .mat-row {
      &.expandable,
      &.clickable {
        cursor: pointer;
      }

      &.disabled {
        > td {
          > * {
            opacity: 0.5;
          }
        }
      }
    }

    .mat-row:not(.detail-row, .expanded-row) {
      &:hover {
        background-color: map-get($background, hover);
      }
    }

    .detail-row-content {
      padding: $padding;
      background-color: map-get($background, background);
    }

    @include phone {
      thead {
        display: none;
      }
    }
  }

  .table-action-menu-item {
    &.warn {
      color: mat.get-color-from-palette($warn);

      mat-icon {
        color: mat.get-color-from-palette($warn);
      }
    }
  }

  mat-bottom-sheet-container {
    &.table-bottom-sheet {
      padding: 0;
    }
  }

  etn-bottom-sheet-actions {
    etn-list-item {
      &.warn {
        .mat-list-item-content,
        etn-icon {
          color: mat.get-color-from-palette($warn);
        }
      }
    }

    .title {
      font-weight: 400 !important;
    }
  }
}
