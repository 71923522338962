@use '@angular/material' as mat;

@mixin autocomplete-theme($theme, $typography) {
  $warn: map-get($theme, warn);

  etn-autocomplete {
    .action-error {
      color: mat.get-color-from-palette($warn) !important;
    }

    .mat-form-field-suffix {
      width: $spacing;
      margin: 0 !important;
      text-align: right;
    }
  }
}
