@use '@angular/material' as mat;

@mixin toolbar-theme($theme, $typography) {
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);

  etn-toolbar {
    .mat-toolbar {
      color: mat.get-color-from-palette($primary, '500-contrast');
    }

    .title-container {
      .title {
        font-size: mat.font-size($typography, title);

        > * {
          display: inline;
        }
      }

      .subtitle {
        font-size: mat.font-size($typography, body-2);

        > * {
          display: inline;
        }
      }

      .info {
        > * {
          display: inline;
        }
      }

      .action-label {
        font-weight: 300;
      }
    }

    .right-content {
      font-size: mat.font-size($typography, subheading-1);
    }
  }
}
