$half-unit: 4px;
$unit: 8px;
$padding: $unit * 2;
$spacing: 24px;
$large-spacing: 40px;
$selectable-item-min-height: 40px;
$top-app-bar-height-compact: 3.5rem;
$top-app-bar-height: 5.5rem;
$border-radius: $unit;
$context-bar-height: 56px;
$form-field-height: 88px;
$wizard-spacing: 8%;
$tooltip-max-width: 296px;
$card-width: 560px;
$fab-card-width: 448px;
$form-field-width: 96px;
$form-width: ($form-field-width + ($unit * 2)) * 8;
$app-menu-footer-height: 64px;
$action-bar-height: $padding * 4;
$drawer-width: 360px;
$basic-card-width: 1024px;
$list-header-height: 56px;
