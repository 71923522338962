@import 'theme/variables';
@import 'theme/mixins';

@mixin save-bar-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  etn-save-bar {
    background-color: map-get($background, card);
    border-top: 1px solid map-get($foreground, divider);

    @include phone {
      &:not(.allow-cancel) {
        etn-action {
          width: 100%;

          button {
            width: 100%;
          }

          .action-label {
            text-align: center !important;
          }
        }
      }
    }
  }
}
