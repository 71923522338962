@mixin app-bar-theme($theme) {
  etn-app-bar {
    mat-toolbar {
      height: auto !important;
    }

    mat-toolbar-row {
      height: 100% !important;
      white-space: normal !important;
    }

    blui-three-liner {
      padding-top: $padding;
      padding-bottom: $padding;

      .blui-three-liner-content {
        min-height: 1.5rem;
      }

      .blui-three-liner-title {
        line-height: 1.5rem;
      }

      .blui-three-liner-subtitle {
        font-size: 1rem;
      }
    }
  }
}
