@mixin permission-header-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $custom-role-font-size: 1.125rem;

  app-permission-header {
    .back-button {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: 600;
      font-size: mat.font-size($typography, body-1);
    }

    .title {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: 600;
    }

    .role {
      color: mat.get-color-from-palette($primary, 300);
      font-weight: 600;
      font-size: $custom-role-font-size;
    }
  }
}
