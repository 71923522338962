@mixin authorize-application-theme($theme, $typography) {
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);

  app-authorize-application {
    .sites-container {
      border-color: $divider;
    }
  }
}
