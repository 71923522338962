@use '@angular/material' as mat;

@mixin list-header-theme($theme) {
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);
  $accent: map-get($theme, accent);

  etn-list-header {
    .blui-info-list-item {
      .mat-list-text {
        color: mat.get-color-from-palette($accent) !important;
      }
    }

    &.top-divider {
      .blui-info-list-item {
        border-top: 1px solid $divider;
      }
    }

    .blui-info-list-item-divider {
      display: none;
    }

    &.bottom-divider {
      .blui-info-list-item-divider {
        display: block;
      }
    }
  }
}
