@import '@brightlayer-ui/colors/palette';

@mixin invitation-header-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $custom-role-font-size: 1.125rem;

  app-invitation-header {
    .back-button {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: 600;
      font-size: mat.font-size($typography, body-1);
    }

    .title {
      color: mat.get-color-from-palette($primary, 500);
      font-weight: 600;
    }

    .subtitle {
      color: mat.get-color-from-palette($blui-orange, 300);
      font-weight: 600;
      font-size: $custom-role-font-size;
    }
  }
}
