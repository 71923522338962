@use '@angular/material' as mat;

@import '@brightlayer-ui/colors/palette';
@import 'theme/variables';

@mixin time-line-theme($theme, $typography) {
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $border-transparent: 1px solid transparent;
  $divider: map-get($blui-gray, 900);

  etn-time-line {
    .fc {
      .fc-datagrid-cell-cushion {
        padding: 0;
      }

      // current time indicator
      .fc-timeline-now-indicator-arrow,
      .fc-timeline-now-indicator-line {
        color: $divider;
        border-color: $divider;
      }

      .fc-timeline-now-indicator-arrow {
        border-right-color: transparent;
        border-left-color: transparent;
      }

      .fc-resource-timeline-divider {
        width: 0;
        background: transparent;
        border: 0;
        cursor: none;
      }
    }

    .fc-h-event {
      background-color: transparent;
      border: none;
    }

    .fc-timeline-event {
      margin-top: $unit;
      padding: 0;
    }

    .fc-theme-standard {
      .fc-scrollgrid {
        border: $border-transparent;
      }

      th {
        font-weight: mat.font-weight($typography, subheading-1);
        font-size: mat.font-size($typography, subheading-1);
        line-height: $unit * 2;
        border-top: $border-transparent;
        border-right: $border-transparent;
        border-left: $border-transparent;
      }
    }

    td {
      &:first-child {
        div {
          &.fc-scroller-harness {
            border-left: 1px solid var(--fc-border-color);
          }
        }
      }
    }

    .resource-label-container {
      .resource-label {
        font-weight: mat.font-weight($typography, subheading-1);
        font-size: mat.font-size($typography, subheading-1);
      }
    }

    // colors
    .event-content {
      .event-description {
        font-weight: mat.font-weight($typography, caption);
        font-size: mat.font-size($typography, caption);
        line-height: mat.line-height($typography, caption);
      }

      .event-title {
        font-weight: mat.font-weight($typography, subheading-1);
        font-size: mat.font-size($typography, subheading-1);
        line-height: mat.line-height($typography, subheading-1);
      }

      &.accent {
        color: mat.get-color-from-palette($accent, '500-contrast');
        background-color: mat.get-color-from-palette($accent);
      }

      &.warn {
        color: mat.get-color-from-palette($warn, '500-contrast');
        background-color: mat.get-color-from-palette($warn);
      }

      &.primary {
        color: mat.get-color-from-palette($primary, '500-contrast');
        background-color: mat.get-color-from-palette($primary);
      }

      &.primary-contrast {
        color: mat.get-color-from-palette($primary, '500');
        background-color: mat.get-color-from-palette($primary, '500-contrast');
      }

      &.green {
        color: map-get($blui-green, '500-contrast');
        background-color: map-get($blui-green, 500);
      }

      &.red {
        color: map-get($blui-red, '500-contrast');
        background-color: map-get($blui-red, 500);
      }

      &.blue {
        color: map-get($blui-blue, '500-contrast');
        background-color: map-get($blui-blue, 500);
      }

      &.lightblue {
        color: map-get($blui-lightBlue, '500-contrast');
        background-color: map-get($blui-lightBlue, 500);
      }

      &.orange {
        color: map-get($blui-orange, '500-contrast');
        background-color: map-get($blui-orange, 500);
      }
    }

    .line-content {
      &.accent {
        background-color: mat.get-color-from-palette($accent, 50);
      }

      &.warn {
        background-color: mat.get-color-from-palette($warn, 50);
      }

      &.primary {
        background-color: mat.get-color-from-palette($primary, 50);
      }

      &.primary-contrast {
        background-color: mat.get-color-from-palette($primary, '50-contrast');
      }

      &.green {
        background-color: map-get($blui-green, 50);
      }

      &.red {
        background-color: map-get($blui-red, 50);
      }

      &.blue {
        background-color: map-get($blui-blue, 50);
      }

      &.lightblue {
        background-color: map-get($blui-lightBlue, 50);
      }

      &.orange {
        background-color: map-get($blui-orange, 50);
      }
    }
  }
}
