@mixin title-theme($theme, $typography) {
  $primary: map-get($theme, primary);

  [appTitle] {
    color: mat.get-color-from-palette($primary);
    font-weight: mat.font-weight($typography, title);
    font-size: mat.font-size($typography, title);
    line-height: mat.line-height($typography, title);
  }
}
