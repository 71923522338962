@mixin slide-toggle-theme($theme) {
  etn-slide-toggle {
    &.right {
      .mat-slide-toggle-label {
        flex-direction: row-reverse;

        .mat-slide-toggle-bar {
          margin: 0 $spacing;
        }
      }
    }
  }
}
