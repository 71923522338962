@mixin breadcrumb-theme($theme) {
  $foreground: map-get($theme, foreground);
  $secondary-text: map-get($foreground, secondary-text);

  etn-breadcrumb {
    etn-icon {
      color: $secondary-text;
    }

    div {
      &:last-of-type {
        etn-icon {
          display: none;
        }
      }
    }
  }
}
