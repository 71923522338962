@use '@angular/material' as mat;

@mixin bems-top-bar-theme($theme, $typography) {
  $title: map-get($typography, display-2);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $toolbar-menu-label-min-width: 152px;
  $toolbar-menu-label-max-width: 15vw;

  app-top-bar {
    app-weather,
    .blui-channel-value-value,
    .blui-channel-value-units {
      color: mat.get-color-from-palette($primary, '500-contrast');
    }

    etn-toolbar-menu {
      padding: $half-unit $unit $half-unit $padding;
      border: 1px solid mat.get-color-from-palette($primary, 400);
      border-radius: $border-radius;

      .label {
        min-width: $toolbar-menu-label-min-width;
        max-width: $toolbar-menu-label-max-width;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .notifications-container {
      &.info {
        .counter {
          background-color: mat.get-color-from-palette($blui-green, 500);
        }
      }

      &.warning {
        .counter {
          background-color: mat.get-color-from-palette($blui-gold, 500);
        }
      }

      &.critical {
        .counter {
          background-color: mat.get-color-from-palette($blui-red, 500);
        }
      }
    }
  }
}
