@import '@brightlayer-ui/colors/palette';

@mixin log-theme($theme) {
  etn-logs {
    .detail-row-content {
      width: 100%;

      .log-container {
        width: 50%;
      }
    }
  }

  .fullscreen-panel {
    display: grid;

    .log-details-container {
      color: map-get($blui-gray, contrast, 900);
      background-color: map-get($blui-gray, 900);
    }
  }
}
