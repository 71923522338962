@use '@angular/material' as mat;

@import '@brightlayer-ui/colors/palette';

@mixin chip-theme($theme) {
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);

  etn-chip {
    &.warn {
      mat-chip {
        color: mat.get-color-from-palette($warn) !important;
        background-color: mat.get-color-from-palette($warn, 50) !important;
      }
    }

    &.primary {
      mat-chip {
        color: mat.get-color-from-palette($primary, '500-contrast') !important;
        background-color: mat.get-color-from-palette($primary, 500) !important;
      }
    }

    &.accent {
      mat-chip {
        color: mat.get-color-from-palette($accent, '500-contrast') !important;
        background-color: mat.get-color-from-palette($accent, 500) !important;
      }
    }

    &.yellow {
      mat-chip {
        color: white !important;
        background-color: map-get($blui-yellow, 500) !important;
      }
    }

    &.green {
      mat-chip {
        color: white !important;
        background-color: map-get($blui-green, 500) !important;
      }
    }

    &.orange {
      mat-chip {
        color: white !important;
        background-color: map-get($blui-orange, 500) !important;
      }
    }

    &.gray {
      mat-chip {
        color: white !important;
        background-color: map-get($blui-gray, 500) !important;
      }
    }

    &.red {
      mat-chip {
        color: white !important;
        background-color: map-get($blui-red, 500) !important;
      }
    }

    &.blue {
      mat-chip {
        color: white !important;
        background-color: map-get($blui-blue, 500) !important;
      }
    }

    &.light-blue {
      mat-chip {
        color: map-get($blui-blue, 500) !important;
        background-color: map-get($blui-blue, 50) !important;
      }
    }

    &.black {
      mat-chip {
        color: white !important;
        background-color: map-get($blui-black, 500) !important;
      }
    }

    &.black-inverted {
      mat-chip {
        color: map-get($blui-black, 500) !important;
        background-color: map-get($blui-white, 50) !important;
        border: 1px solid map-get($blui-black, 500);
      }
    }
  }
}
