@use '@angular/material' as mat;

@import 'theme/variables';

@mixin wizard-theme($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);

  etn-wizard {
    .mat-step-label {
      font-size: mat.font-size($typography, body-2);
    }

    .mat-step-label-selected {
      font-weight: bold;
    }

    .mat-horizontal-stepper-wrapper {
      height: 100%;
    }

    .mat-horizontal-content-container {
      width: $form-width;
      height: 100%;
      margin: auto;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &.full-width {
      .mat-horizontal-content-container {
        width: calc(100% - #{$spacing * 2});
      }
    }

    .mat-stepper-horizontal {
      height: calc(100% - #{$action-bar-height});
      overflow: auto;
      background-color: transparent;
    }

    .mat-horizontal-stepper-header-container {
      min-width: $form-width;
      height: $context-bar-height * 2;
      margin: auto;
      padding: $spacing 0;

      etn-icon {
        height: 19px;
        color: mat.get-color-from-palette($accent, '500-contrast');
      }
    }

    .actions-container {
      height: $action-bar-height;
      padding: 0 $padding;
      line-height: $action-bar-height;
      background-color: map-get($background, card);
    }

    .bullets {
      span {
        background-color: $divider;

        &.current {
          background-color: mat.get-color-from-palette($accent);
        }
      }
    }

    &.bullets {
      .mat-horizontal-stepper-header-container {
        display: none;
      }
    }

    @include phone {
      padding-top: 0 !important;

      .mat-horizontal-stepper-header-container {
        display: none;
      }

      .mat-horizontal-content-container {
        padding: 0;

        mat-card {
          max-width: initial;
          border-radius: 0;
          box-shadow: none !important;
        }
      }
    }
  }
}
