@use '@angular/material' as mat;

@import '@brightlayer-ui/colors/palette';

@mixin multi-language-form-field-theme($theme) {
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);

  etn-multi-language-form-field {
    etn-icon {
      &.valid {
        color: map-get($blui-green, 500);
      }

      &.invalid {
        color: mat.get-color-from-palette($warn);
      }
    }

    .mat-tab-group {
      .mat-tab-label {
        color: map-get($foreground, secondary-text);
        text-transform: uppercase;
      }

      .mat-tab-label-active {
        color: mat.get-color-from-palette($accent);
      }
    }
  }
}
