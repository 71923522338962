@use 'sass:math';
@use '@angular/material' as mat;

@import 'theme/variables';

@mixin color-picker-theme($theme) {
  $foreground: map-get($theme, foreground);
  $warn: map-get($theme, warn);

  etn-color-picker {
    color-picker {
      position: absolute;
      top: 0;
      left: 0;
      width: math.div($form-field-height, 2);
      min-width: math.div($form-field-height, 2);
      height: math.div($form-field-height, 2);
    }

    .color-picker {
      border: 1px solid map-get($foreground, 'divider');
    }

    etn-icon {
      mat-icon {
        width: 62px !important;
        height: 62px !important;
        color: map-get($foreground, 'divider');
        font-size: 62px !important;
      }
    }
  }
}
