@use '@angular/material' as mat;

@import '@brightlayer-ui/colors/palette';
@import 'theme/variables';

@mixin top-bar-theme($theme) {
  $primary: map-get($theme, primary);

  etn-top-bar {
    mat-toolbar {
      color: mat.get-color-from-palette($primary, '500-contrast') !important;
    }

    &.title-only {
      mat-toolbar {
        height: $top-app-bar-height-compact;
      }
    }

    etn-toolbar {
      &.mobile-view {
        mat-toolbar {
          height: $top-app-bar-height-compact;
        }
      }

      .info {
        display: flex;
        line-height: $padding;
      }

      .title {
        line-height: 1.75rem;
      }
    }

    etn-top-bar-breadcrumbs {
      color: mat.get-color-from-palette($primary, '500-contrast');

      button {
        padding: 0 $unit;
        line-height: $spacing;
      }

      .mat-button-disabled {
        color: mat.get-color-from-palette($primary, '500-contrast') !important;
        background-color: initial !important;
      }

      etn-icon {
        color: map-get($blui-gray, 200);
      }
    }
  }
}
