@use 'sass:math';

@import 'theme/variables';

html,
body {
  height: 100%;
  overflow: hidden;
  font-size: 16px;
}

body {
  margin: 0;
  overflow: hidden;
  background-image: url('./assets/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
