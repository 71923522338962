/* stylelint-disable selector-class-pattern */
@use 'sass:math';
@use '@angular/material' as mat;

@import 'theme/mixins';

@mixin phone-input-theme($theme, $typography) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $caption: mat.line-height($typography, caption);
  $input: map-get($typography, input);
  $primary: map-get($theme, accent);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $config: mat.get-color-config($theme);
  $is-dark-theme: map-get($config, is-dark);
  $popup-height: 150px;

  etn-phone-input {
    .mat-form-field-prefix {
      margin-top: 0.3rem;
      margin-bottom: 0 !important;
    }

    .iti {
      width: $spacing + $unit;

      input {
        display: none;
      }

      .iti__flag-container {
        position: relative;
      }

      .iti__selected-flag {
        padding: 0;
        background-color: transparent !important;
      }
    }
  }

  .iti--fullscreen-popup {
    .iti__dropdown-content {
      width: 50%;
      height: 50%;
      margin: auto;
    }

    .iti__country-list {
      width: 100% !important;
    }
  }

  @include smallscreen {
    .iti--fullscreen-popup {
      .iti__dropdown-content {
        width: 75%;
        height: 75%;
        margin: auto;
      }
    }
  }

  @include phone {
    .iti--fullscreen-popup {
      .iti__dropdown-content {
        width: auto;
        height: auto;
        margin: auto;
      }
    }
  }
}
