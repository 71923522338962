/* stylelint-disable selector-class-pattern */
@use '@angular/material' as mat;

@mixin tour-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $base: map-get($foreground, base);
  $accent: map-get($theme, accent);

  etn-tour-counter {
    span {
      background-color: map-get($background, disabled-button);

      &.active {
        background-color: mat.get-color-from-palette($accent);
      }
    }
  }

  .joyride-backdrop {
    &.backdrop-top,
    &.backdrop-bottom,
    &.backdrop-left,
    &.backdrop-right {
      background-color: transparent !important;
    }
  }

  .backdrop-target {
    height: calc(100% + #{$unit * 2});
    margin-top: -$unit;
    margin-left: -$unit;
    padding-right: $unit * 2;
    border-radius: $border-radius;
    box-shadow: 0 0 0 150vw transparentize($color: $base, $amount: 0.5);
  }

  div {
    &.joyride-step__container {
      @include mat.elevation(4);

      padding: $spacing;
      background-color: map-get($background, card);
      border-radius: $border-radius;
    }

    .joyride-step__title {
      width: calc(100% - #{$spacing});
    }

    &.joyride-step__header,
    &.joyride-step__body,
    &.joyride-step__footer {
      padding: 0;
      color: $base;
    }

    &.joyride-step__body {
      max-width: 512px;
      margin: $spacing 0;
    }

    &.joyride-step__counter-container {
      margin-right: $spacing;
    }

    joy-close-button {
      &.joyride-step__close {
        top: $spacing;
        right: $spacing;
      }
    }
  }
}
