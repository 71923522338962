@use '@angular/material' as mat;

@import 'theme/variables';
@import 'theme/mixins';
@import '@brightlayer-ui/colors/palette';

$select-width: 224px;

@mixin table-container-theme($theme, $typography) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);

  etn-table-container {
    etn-checkbox-menu {
      button {
        margin-right: $unit;
        padding: 0;
      }
    }

    etn-select {
      .mat-form-field-wrapper {
        width: $select-width;
        max-width: $select-width;
        border-right: 1px solid $divider;
      }

      .mat-form-field-flex {
        background-color: transparent !important;
      }

      .mat-form-field-infix {
        padding-top: 2rem !important;
        padding-bottom: 1.3rem !important;
      }

      .mat-form-field-suffix {
        padding-top: 1.5rem !important;
      }
    }

    etn-search {
      .action-clear {
        margin-right: 0;
      }
    }

    .actions-container {
      etn-chip {
        mat-chip {
          cursor: pointer;
        }
      }
    }

    .table-header {
      mat-card {
        border-top: 1px solid $divider;
        border-bottom: 1px solid $divider;
        border-radius: 0;

        .mat-divider-horizontal {
          top: 0;
        }

        .mat-card-content {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          min-height: $context-bar-height;
          max-height: $context-bar-height;
          overflow: hidden;
        }
      }
    }

    .alt-table-header {
      mat-card {
        border-top: 1px solid $divider;
        border-bottom: 1px solid $divider;
        border-radius: 0;

        .mat-divider-horizontal {
          top: 0;
        }

        .mat-card-content {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          overflow: hidden;
        }
      }
    }

    .table-alt-toolbar {
      mat-card {
        border-radius: 0;

        .mat-divider-horizontal {
          top: 0;
        }
      }
    }

    .table-content {
      > mat-card {
        > mat-card-content {
          border-radius: 0;
        }
      }
    }

    etn-card {
      .mat-card {
        padding: 0;
        border-radius: 0;
      }
    }

    etn-table-pagination {
      margin: 0 $unit * 2;
    }

    .table-header-actions-container {
      etn-action {
        margin-right: $unit;
      }
    }

    // Styles for table container inside table container (details row)
    .detail-row-content {
      etn-table-container {
        margin: 0;
      }
    }

    &.detail-row-position-right.detail-rows-expanded {
      .table-content {
        mat-card {
          min-height: 50vh;
        }
      }

      etn-table {
        width: 50%;
        border-right: 1px solid $divider;

        .detail-row-content {
          position: absolute;
          top: 0;
          left: calc(50% + 1px);
          height: 100% !important;
          padding: 0;

          .example-element-container {
            overflow: auto;
          }
        }

        .expanded-row {
          background-color: map-get($blui-gray, 50);
        }
      }
    }

    @include phone {
      .mat-card {
        max-width: initial !important;
        box-shadow: none !important;
      }
    }
  }

  .table-container-filters-dialog {
    etn-dialog {
      .title-container {
        color: mat.get-color-from-palette($primary, 500);
      }
    }
  }

  etn-filters-dialog {
    .filter-title {
      color: mat.get-color-from-palette($primary, 500);
    }

    etn-date-range-picker,
    etn-month-picker {
      .mat-form-field-flex {
        padding-top: 0;
      }

      label {
        top: 1.5rem !important;
      }

      .mat-form-field-should-float {
        label {
          display: none !important;
        }
      }
    }
  }
}
