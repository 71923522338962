@use '@angular/material' as mat;

@mixin bems-icon-theme($theme, $typography) {
  $subheading1: map-get($typography, subheading-1);

  .soc-value {
    font-weight: map-get($subheading1, font-weight);
      font-size: map-get($subheading1, font-size);
  }
}
