@import 'theme/variables';
@import 'theme/mixins';

@mixin address-form-field-theme($theme) {
  etn-address-form-field {
    @include phone {
      .mat-list-icon {
        display: none;
      }
    }
  }

  etn-address-form-field-dialog {
    etn-address-picker {
      .mat-form-field-underline {
        bottom: 0;
      }
    }
  }
}
