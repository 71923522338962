@use '@angular/material' as mat;

@mixin wizard-dialog-theme($theme) {
  $accent: map-get($theme, accent);

  etn-wizard-dialog {
    .mat-horizontal-content-container {
      padding: 0;
    }

    .subtitle {
      color: mat.get-color-from-palette($accent);
    }
  }

  .etn-wizard-dialog {
    mat-dialog-container {
      padding: 0;
      overflow: hidden;
    }

    .title-container {
      padding: $unit * 2 $unit * 2 0 $unit * 2;
    }
  }
}
