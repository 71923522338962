@use '@angular/material' as mat;

@import 'theme/variables';

@mixin tooltip-theme($theme, $typography) {
  $tooltip-margin: $half-unit;
  $body2: map-get($typography, body-2);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  etn-tooltip {
    max-width: $tooltip-max-width;
    color: map-get($background, background);
    font-size: map-get($body2, font-size);
    background-color: map-get($foreground, text);

    .right ::after {
      border-color: transparent map-get($foreground, text) transparent
        transparent;
    }

    .left ::after {
      border-color: transparent transparent transparent
        map-get($foreground, text);
    }

    .top ::after {
      border-color: map-get($foreground, text) transparent transparent
        transparent;
    }

    .bottom ::after {
      border-color: transparent transparent map-get($foreground, text)
        transparent;
    }

    @include mat.elevation(4);
  }

  mat-tooltip-component {
    .mat-mdc-tooltip {
      animation-duration: 0ms;

      &.right::after {
        position: absolute;
        top: $unit;
        left: -$unit;
        border-color: transparent map-get($foreground, text) transparent
          transparent;
        border-style: solid;
        border-width: $tooltip-margin;
        content: '';
      }

      &.left::after {
        position: absolute;
        top: $unit;
        left: 100%;
        border-color: transparent transparent transparent
          map-get($foreground, text);
        border-style: solid;
        border-width: $tooltip-margin;
        content: '';
      }

      &.top::after {
        position: absolute;
        top: 100%;
        left: calc(50% - #{$half-unit});
        border-color: map-get($foreground, text) transparent transparent
          transparent;
        border-style: solid;
        border-width: $tooltip-margin;
        content: '';
      }

      &.bottom::after {
        position: absolute;
        top: -$unit;
        left: calc(50% - #{$half-unit});
        border-color: transparent transparent map-get($foreground, text)
          transparent;
        border-style: solid;
        border-width: $tooltip-margin;
        content: '';
      }

      /* stylelint-disable-next-line selector-class-pattern */
      .mdc-tooltip__surface {
        max-width: $tooltip-max-width;
        color: map-get($background, background);
        font-size: map-get($body2, font-size);
        text-align: center;
        background-color: map-get($foreground, text);
        border-radius: $border-radius;
      }
    }
  }
}
