@import 'theme/variables';
@import '@brightlayer-ui/colors/palette';

@mixin nested-expansion-panel-theme($theme) {
  $background: map-get($theme, background);

  etn-nested-expansion-panel {
    &.nested-background {
      background: map-get($background, background);
    }
  }
}
