@use '@angular/material' as mat;

@mixin list-item-theme($theme) {
  $foreground: map-get($theme, foreground);
  $divider: map-get($foreground, divider);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);

  etn-list-item-tag {
    &.green {
      blui-list-item-tag {
        background-color: map-get($blui-green, 500);
      }
    }

    &.orange {
      blui-list-item-tag {
        color: map-get($blui-orange, contrast, 500);
        background-color: map-get($blui-orange, 500);
      }
    }

    &.blue {
      blui-list-item-tag {
        color: map-get($blui-blue, contrast, 500);
        background-color: map-get($blui-blue, 500);
      }
    }

    &.white {
      blui-list-item-tag {
        color: map-get($blui-black, 500);
        background-color: map-get($blui-white, 50);
        border: 1px solid map-get($blui-black, 500);
      }
    }

    &.light-blue {
      blui-list-item-tag {
        color: map-get($blui-blue, 500);
        background-color: map-get($blui-blue, 50);
      }
    }

    &.light-green {
      blui-list-item-tag {
        color: map-get($blui-green, 500);
        background-color: map-get($blui-green, 50);
      }
    }

    &.light-gray {
      blui-list-item-tag {
        color: map-get($blui-gray, 500);
        background-color: map-get($blui-gray, 50);
      }
    }

    &.yellow {
      blui-list-item-tag {
        color: map-get($blui-black, 500);
        background-color: map-get($blui-yellow, 500);
      }
    }

    &.red {
      blui-list-item-tag {
        color: map-get($blui-red, contrast, 500);
        background-color: map-get($blui-red, 500);
      }
    }

    &.gray {
      blui-list-item-tag {
        color: map-get($blui-gray, contrast, 500);
        background-color: map-get($blui-gray, 500);
      }
    }

    &.purple {
      blui-list-item-tag {
        color: map-get($blui-purple, contrast, 500);
        background-color: map-get($blui-purple, 500);
      }
    }
  }

  etn-list-item-separator {
    border-color: $divider;
  }

  etn-list-item {
    .blui-info-list-item-icon-wrapper {
      etn-icon {
        width: auto !important;
        height: auto !important;
      }
    }

    blui-info-list-item {
      .blui-info-list-item-divider {
        display: none;
      }
    }

    &.active {
      blui-info-list-item {
        color: mat.get-color-from-palette($accent);
        background-color: mat.get-color-from-palette($accent, 50);
      }
    }

    &.bottom-divider {
      blui-info-list-item {
        .blui-info-list-item-divider {
          display: block;
        }
      }
    }

    &.flexible-height {
      .mat-list-item-content {
        align-items: center;
        height: auto;
      }

      .mat-list-icon {
        align-items: center;
      }

      .blui-info-list-item-subtitle-wrapper {
        .subtitle * {
          white-space: normal;
        }
      }
    }

    etn-action {
      button {
        &.mat-accent {
          color: mat.get-color-from-palette($accent) !important;
          background-color: transparent !important;
        }
      }
    }

    @include phone {
      .mat-list-text {
        .title,
        .subtitle {
          > * {
            overflow: visible !important;
            white-space: initial !important;
            text-overflow: initial !important;
          }
        }
      }
    }
  }
}
